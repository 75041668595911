import React from 'react'

import clsx from 'clsx'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'
import ImageSwitcher from 'components/ImageSwitcher'
import { TuixTextComponent } from 'components/TuixWebcomponents'
import { Palette, TextSize, TextVariant } from 'tuix-webcomponents'
import './ImpressumTitle.scss'

type Props = {
    iconURL: string
    darkIconURL: string
    title: string
}

export default ({ iconURL, darkIconURL, title }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <div className={clsx('impressum__backgroundAndContent', isDarkMode && 'dark')}>
            <div className="impressum__TitleAndImageContainer">
                <ImageSwitcher
                    className={clsx('impressum_LogoContainer', isDarkMode && 'dark')}
                    lightImageURL={iconURL}
                    darkImageURL={darkIconURL}
                />
                <div className="impressum_TitleContainer">
                    <TuixTextComponent
                        dark={isDarkMode}
                        color={TextVariant.TITLE_BLUE}
                        size={TextSize.XL}
                    >
                        {title}
                    </TuixTextComponent>
                </div>
            </div>
        </div>
    )
}
