import * as React from 'react'

import clsx from 'clsx'
import { useDarkModeContext } from 'components/hooks/darkMode/useDarkModeContext'

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
    lightImageURL: string
    darkImageURL: string
}

export default ({ lightImageURL, darkImageURL, className, ...rest }: Props): JSX.Element => {
    const { isDarkMode } = useDarkModeContext()

    return (
        <img
            src={isDarkMode ? darkImageURL : lightImageURL}
            alt={isDarkMode ? 'dark image switcher' : 'light image switcher'}
            className={clsx(isDarkMode && 'dark-image', className)}
            {...rest}
        />
    )
}
